<template>
  <div class="container mx-auto pb-5 max-w-[1300px]">
    <img class="object-cover saturate-150 w-full" :src="`https://www.actonchina.com${cat1_banner}`">

    <div class="py-6">
      <div v-for="piano in pianos" :key="piano.id">
        <!--div class="grid grid-flow-col auto-cols-auto	gap-2 text-xs max-w-[250px] pb-5">
          <p class="flex">
            <svg class="pb-[5px]" height="20" width="20" fill="none" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1276_4030)">
                <path
                  d="M1.6665 10.1698C1.6665 8.26275 1.6665 7.30923 2.09917 6.51879C2.53184 5.72834 3.32229 5.23777 4.9032 4.25661L6.56986 3.22223C8.24099 2.18508 9.07656 1.6665 9.99984 1.6665C10.9231 1.6665 11.7587 2.18508 13.4298 3.22223L15.0965 4.25661C16.6774 5.23776 17.4678 5.72834 17.9005 6.51879C18.3332 7.30923 18.3332 8.26275 18.3332 10.1698V11.4373C18.3332 14.688 18.3332 16.3134 17.3569 17.3233C16.3805 18.3332 14.8092 18.3332 11.6665 18.3332H8.33317C5.19047 18.3332 3.61913 18.3332 2.64281 17.3233C1.6665 16.3134 1.6665 14.688 1.6665 11.4373V10.1698Z"
                  stroke="#5F5E5E" strokeWidth="1.5" />
                <path
                  d="M7.5 13.3335C8.20865 13.8588 9.07047 14.1668 10 14.1668C10.9295 14.1668 11.7914 13.8588 12.5 13.3335"
                  stroke="#5F5E5E" strokeLinecap="round" strokeWidth="1.5" />
              </g>
              <defs>
                <clipPath id="clip0_1276_4030">
                  <rect height="20" width="20" fill="white" />
                </clipPath>
              </defs>
            </svg> 首页
          </p>
          <p class="flex">
            <svg class="pb-[5px]" height="20" width="20" fill="none" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 4.16699L12.5 10.0003L7.5 15.8337" stroke="#5F5E5E" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="1.5" />
            </svg> DESIGN
          </p>
          <p class="flex">
            <svg class="pb-[5px]" height="20" width="20" fill="none" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 4.16699L12.5 10.0003L7.5 15.8337" stroke="#5F5E5E" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="1.5" />
            </svg> {{ piano.prd_name.toUpperCase() }}
          </p>
        </div-->
        <div class="flex justify-center">
          <div class="grid gri-cols-1">
            <p class="font-bold text-3xl text-center">{{ piano.prd_name.toUpperCase() }}</p>
            <p class="text-lg text-center py-2 italic">{{ piano.cat1_namecn }}</p>
            <div class="content flex mx-auto max-w-[600px]">
              <img class="object-cover saturate-150" :src="`https://www.actonchina.com${piano.prd_cover}`">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-10 pb-20 text-center">
      <!--a href="/retail/"
        class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">查找经销商</a-->
    </div>

    <div class="flex border-b justify-center border-gray-300 mb-5 lg:min-w-[600px]">
      <div class="flex justify-center">
        <button
          :class="{ 'min-w-[12rem] py-2 px-20 border-b-4 border-gray-900 hover:text-gray-600 text-gray-800 font-bold  transition duration-200': selectedTab === 'tab1', 'py-2 px-10 border-b-4 border-gray-600/30 hover:text-gray-500 text-gray-600 font-bold': selectedTab !== 'tab1', }"
          @click="selectedTab = 'tab1'">钢琴</button>
        <button
          :class="{ 'min-w-[12rem] py-2 px-20 border-b-4 border-red-600 hover:text-gray-600 text-gray-800 font-bold  transition duration-200': selectedTab === 'tab2', 'py-2 px-10 border-b-4 border-red-600/50 hover:text-gray-500 text-gray-600 font-bold': selectedTab !== 'tab2', }"
          @click="selectedTab = 'tab2'">规格</button>
      </div>
    </div>



  </div>

  <div class="content flex mx-auto justify-center lg:min-w-[600px] max-w-[600px] pb-16">
    <div>
      <div class="flex mx-auto w-60">
        <!--img class="object-cover colored-logo" src="/sauterlogo.svg"-->
      </div>
      <div v-if="selectedTab === 'tab1'" class="p-4 lg:min-w-[600px]">
        <div v-html="prd_features"></div>
      </div>

      <div v-if="selectedTab === 'tab2'" class="relative p-4 lg:min-w-[600px] flex justify-center">

        <img src="/images/spec-cover.jpg" class="object-cover">
        <div class="absolute z-10 top-20">
          <p class="py-3 text-lg font-bold">{{ pianos[0].prd_name.toUpperCase() }}</p>
          <p class="" v-html="prd_spec"></p>
        </div>

      </div>
    </div>
  </div>
  <detail-footer></detail-footer>
</template>



<script>
import DetailFooter from '@/components/common/DetailFooter.vue';
import { ref, onMounted } from 'vue';
const pageTitle = ref('首德钢琴 德国SAUTER（首德）钢琴购买指南');

export default {
  components: {
    DetailFooter,
  },
  
  data() {
    return {
      pianos: [],
      prd_features: "",
      cat1_banner: "",
      selectedTab: 'tab1',
      prd_spec: "",
    };
  },

  created() {
    this.fetchProducts();
  },
  methods: {

    // 清理 HTML 内容的函数，移除所有的 style 属性
    cleanHtml(html) {
      // 创建一个 DOM parser
      const parser = new DOMParser();
      // 将 HTML 字符串解析为一个文档对象
      const doc = parser.parseFromString(html, 'text/html');
      // 获取所有的带有 style 属性的元素
      const elementsWithStyle = doc.querySelectorAll('[style]');
      // 移除每一个元素的 style 属性
      elementsWithStyle.forEach(element => element.removeAttribute('style'));
      // 返回清理后的 HTML
      return doc.body.innerHTML;
    },



    async fetchProducts() {
      try {
        const id = this.$route.params.id;

        const apiUrl = `https://api.acton-shanghai.cn/openRDS/api/product/id/${id}`;
        const response = await fetch(apiUrl);
        const pianoArrays = await response.json();
        //console.log(pianoArrays);
        this.pianos = pianoArrays;

        const cat1_id = pianoArrays[0].cat1_id;
        //console.log(cat1_id);
        const cat1Url = `https://api.acton-shanghai.cn/openRDS/api/cat1/list/15/id/${cat1_id}`;
        const responseCat = await fetch(cat1Url);
        const cat1Arrays = await responseCat.json();
        this.cat1_banner = cat1Arrays[0].cat1_banner;
        //console.log(cat1_cover);



        const rawHtml = pianoArrays[0].prd_features;
        // 清理 HTML 内容并存储在 cleanedHtml 中
        this.prd_features = this.cleanHtml(rawHtml);
        this.prd_features = this.cleanHtml(rawHtml).replace(/<img([^>]*)src="([^"]*)"/g, function (match, p1, p2) {
          if (!p2.includes('http')) {
            return `<img${p1}src="https://www.actonchina.com${p2}"`;
          } else {
            return match;
          }
        });

        this.prd_spec = this.cleanHtml(pianoArrays[0].prd_spec).replace(/<img([^>]*)src="([^"]*)"/g);


      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },
  }
}
const tabs = document.querySelectorAll('[data-tab]');
tabs.forEach(tab => {
  tab.addEventListener('click', () => {
    const tabName = tab.dataset.tab;
    const tabContent = document.getElementById(tabName);
    const allTabContents = document.querySelectorAll('.tab-content');
    allTabContents.forEach(content => content.classList.add('hidden'));
    tabContent.classList.remove('hidden');
  });
});
</script>

<style>
.content img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.content P {
  line-height: 28px;
  text-align: justify;
  margin-bottom: 10px;
  color:#353333;
}

.content strong {
  line-height: 35px;
}

.colored-logo {
  filter: invert(100%);
  /* 将SVG图片反相 */
}
</style>
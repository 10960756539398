<template>
    <div class="w-full">
        <img class="object-cover w-full max-h-[390px]" src="/images/head7.jpg">
    </div>
    <p class="py-6 text-center font-bold text-2xl">SAUTER(首德) 认证工作室</p>
    <div class="py-6 max-w-[1600] flex justify-center">
        <div v-if="studiolist.length > 0">
            <div class="grid grid-cols-1 gap-3 lg:grid-cols-2 lg:gap-6 lg:min-w-[800px] lg:max-w-[1000px]" role="none">
                <div v-for="studio in studiolist" :key="studio.id">
                    <a class="cursor-pointer " role="menuitem">
                        <div
                            class="block p-10 border-b border-yellow-700/30 text-sm hover:bg-gray-100/50 transition hover:shadow-lg hover:border-b-4 duration-200 hover:opacity-80">
                            <div class="flex gap-5">
                                <img class="border border-yellow-600/30 h-32 w-32 object-cover"
                                    :src="`${studio.avatar}`">
                                <div class="flex flex-col">
                                    <p class="font-bold text-base">{{ studio.name }}</p>
                                    <p class="text-sm py-3">{{ studio.city }}</p>
                                </div>
                            </div>
                            <p class="text-gray-900 text-base font-bold py-3">介绍</p>
                            <p class="text-gray-700 max-h-[60px] overflow-hidden">{{ studio.introduction }}</p>
                            <p class="text-gray-900 text-base font-bold py-3">荣誉</p>
                            <p class="text-gray-700 max-h-[60px] overflow-hidden">{{ studio.introduction }}</p>
                        </div>

                    </a>
                </div>
            </div>
        </div>
        <div v-else class="flex justify-center pb-16">
            <div class="grid grid-cols-1">
                <p class="text-base font-bold text-center">暂无资料</p>

                <img src="/images/store.png" class="object-cover h-40 m-10">
                <div class="pt-10 text-center">
                    <a href=""
                        class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">成为认证技师</a>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
const pageTitle = ref('首德钢琴认证工作室 德国SAUTER（首德）钢琴购买指南');
const isOpen = ref(false);
const dropdown = ref(null);
const studiolist = ref([]);

const toggleDropdown = (menu) => {
    dropdown.value = dropdown.value === menu ? null : menu;
};

const fetchProducts = async () => {
    try {
        const fetchData = async (url, num) => {
            const response = await fetch(url);
            const data = await response.json();
            return data.slice(0, num);
        };

        const studioArrays = await fetchData('https://api.acton-shanghai.cn/openRDS/api/studio/');
        studiolist.value = studioArrays;
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

onMounted(() => {
    document.title = pageTitle.value;
    fetchProducts();
});
</script>

<template>
  <div class="container mx-auto pb-5 max-w-[1300px]">
    <img class="object-cover saturate-150 w-full" :src="`https://www.actonchina.com${cat1_banner}`">

    <div class="py-6">
      <div v-for="piano in pianos" :key="piano.id">

        <div class="flex justify-center">
          <div class="grid gri-cols-1">
            <p class="font-bold text-3xl text-center">{{ piano.prd_name.toUpperCase() }}</p>
            <p class="text-lg text-center py-2 italic ">{{ piano.cat1_nameen }}</p>
            <div class="content relative flex mx-auto max-w-[600px]">
              <img class="object-cover saturate-100 lg:min-h-[400px] lg:max-h-[400px]"
                :src="`https://www.actonchina.com${piano.prd_cover}`"
                @click="openModal(`https://www.actonchina.com${piano.prd_cover}`)">
              <div class="absolute inset-0 flex items-center justify-center z-10 cursor-pointer" title="点击放大"
                @click="openModal(`https://www.actonchina.com${piano.prd_cover}`)">
                <svg height="50" width="50" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.75 9C12.75 8.58579 12.4142 8.25 12 8.25C11.5858 8.25 11.25 8.58579 11.25 9L11.25 11.25H9C8.58579 11.25 8.25 11.5858 8.25 12C8.25 12.4142 8.58579 12.75 9 12.75H11.25V15C11.25 15.4142 11.5858 15.75 12 15.75C12.4142 15.75 12.75 15.4142 12.75 15L12.75 12.75H15C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25H12.75V9Z"
                    fill="#A0A0A015" />
                  <path
                    d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
                    fill="#A0A0A015" fillRule="evenodd" />
                </svg>
              </div>
            </div>
            <!--popup img-->
            <div v-if="showModal" class="fixed w-full inset-0 bg-white flex items-center justify-center z-10">
              <div class="relative" :class="{ 'fade-in': showModal, 'fade-out': !showModal }">
                <img class="object-contain max-h-full max-w-full" :src="currentImage" />
                <button class="absolute top-0 right-1 text-white rounded-full bg-gray-200" @click="showModal = false">
                  <svg height="40" width="40" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.0303 8.96965C9.73741 8.67676 9.26253 8.67676 8.96964 8.96965C8.67675 9.26255 8.67675 9.73742 8.96964 10.0303L10.9393 12L8.96966 13.9697C8.67677 14.2625 8.67677 14.7374 8.96966 15.0303C9.26255 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9696L13.0606 12L15.0303 10.0303C15.3232 9.73744 15.3232 9.26257 15.0303 8.96968C14.7374 8.67678 14.2625 8.67678 13.9696 8.96968L12 10.9393L10.0303 8.96965Z" fill="#6e6e73" />
                  </svg>
                </button>
              </div>
            </div>
            <!--popup img-->
            <p class="text-center text-sm pt-5 lg:text-sm">
              建议零售价：RMB {{ listprice }} 元
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex border-b justify-center border-gray-300 mb-5 lg:min-w-[600px]">
      <div class="flex justify-center">
        <button
          :class="{ 'min-w-[12rem] py-2 px-20 border-b-4 border-gray-900 hover:text-gray-600 text-gray-800 font-bold  transition duration-200': selectedTab === 'tab1', 'py-2 px-10 border-b-4 border-gray-600/30 hover:text-gray-500 text-gray-600 font-bold': selectedTab !== 'tab1', }"
          @click="selectedTab = 'tab1'">钢琴</button>
        <button
          :class="{ 'min-w-[12rem] py-2 px-20 border-b-4 border-red-600 hover:text-gray-600 text-gray-800 font-bold  transition duration-200': selectedTab === 'tab2', 'py-2 px-10 border-b-4 border-red-600/50 hover:text-gray-500 text-gray-600 font-bold': selectedTab !== 'tab2', }"
          @click="selectedTab = 'tab2'">规格</button>
        <button v-if="uni_disc"
          :class="{ 'min-w-[12rem] py-2 px-20 border-b-4 border-yellow-600 hover:text-gray-600 text-gray-800 font-bold  transition duration-200': selectedTab === 'tab3', 'py-2 px-10 border-b-4 border-yellow-600/30 hover:text-gray-500 text-gray-600 font-bold': selectedTab !== 'tab3', }"
          @click="selectedTab = 'tab3'">细节</button>
      </div>
    </div>



  </div>

  <div class="content flex mx-auto justify-center lg:min-w-[600px] max-w-[600px] pb-16">
    <div>
      <div class="flex mx-auto w-60 pb-10">
        <img class="object-cover" src="https://www.actonchina.com/imgs/Brand/20180423134145035.jpg">
      </div>
      <div v-if="selectedTab === 'tab1'" class="p-4 lg:min-w-[600px]">
        <div v-html="prd_features"></div>
      </div>

      <div v-if="selectedTab === 'tab2'" class="relative p-4 lg:min-w-[600px] flex justify-center">

        <img src="/images/spec-cover.jpg" class="object-cover">
        <div class="absolute z-10 top-10 lg:top-20">
          <p class="py-3 text-lg font-bold">{{ pianos[0].prd_name.toUpperCase() }}</p>
          <p class="" v-html="prd_spec"></p>
        </div>

      </div>
      <div v-if="selectedTab === 'tab3'" class="p-4  lg:min-w-[600px]">
        <p v-html="uni_disc"></p>
      </div>
    </div>
  </div>
  <p class="text-center font-bold text-xl my-10 pb-2 border-b ">更多视听</p>
  <div class="flex justify-center border-gray-300 mb-5 lg:max-w-[600px] mx-auto">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 mx-5 lg:mx-0">
      <div v-for="video in videos" :key="video.id">
        <a class="relative" :href="`${video.v_url}`" target="_blank">
          <div class="hover:-translate-y-1 duration-200 hover:opacity-85">
            <div
              class="flex justify-center rounded-md border border-yellow-600/20 p-1 hover:border-2 hover:border-yellow-600/60 duration-200">
              <img
                class="object-fill overflow-hidden saturate-100 lg:min-w-[278px] lg:max-h-[190px] lg:min-h-[190px] z-0"
                :src="`${video.v_cover}`">
            </div>
          </div>
          <div class="absolute top-[80px] z-1 w-full">
            <div class="flex mx-auto justify-center"><svg height="48" width="48" fill="none" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="white" strokeWidth="1.5" />
                <path
                  d="M13.8876 9.9348C14.9625 10.8117 15.5 11.2501 15.5 12C15.5 12.7499 14.9625 13.1883 13.8876 14.0652C13.5909 14.3073 13.2966 14.5352 13.0261 14.7251C12.7888 14.8917 12.5201 15.064 12.2419 15.2332C11.1695 15.8853 10.6333 16.2114 10.1524 15.8504C9.6715 15.4894 9.62779 14.7336 9.54038 13.2222C9.51566 12.7947 9.5 12.3757 9.5 12C9.5 11.6243 9.51566 11.2053 9.54038 10.7778C9.62779 9.26636 9.6715 8.51061 10.1524 8.1496C10.6333 7.78859 11.1695 8.11466 12.2419 8.76679C12.5201 8.93597 12.7888 9.10831 13.0261 9.27492C13.2966 9.46483 13.5909 9.69274 13.8876 9.9348Z"
                  stroke="white" strokeWidth="1.5" />
              </svg></div>
          </div>
          <ul>
            <li class="pt-5">
              <div class="text-sm text-center">{{ video.prd_name.toUpperCase() }}</div>
              <div class="text-sm lg:min-h-[64px] font-semibold py-3 min-h-[60px]">
                {{ video.v_title }}
              </div>
            </li>
            <div class="lg:pt-6 pb-10 text-center">
              <a :href="`${video.v_url}`" target="_blank"
                class="border border-gray-600 rounded-full px-10 py-[12px] text-sm tracking-wider hover:bg-black hover:text-white duration-300">了解更多</a>
            </div>
          </ul>
        </a>
      </div>
      <div v-for="article in articles" :key="article.id">
        <a class="" :href="`${article.n_url}`" target="_blank">
          <div class="hover:-translate-y-1 duration-200 hover:opacity-85">
            <div
              class="flex justify-center rounded-md border border-yellow-600/20 p-1 hover:border-2 hover:border-yellow-600/60 duration-200">
              <img class="object-cover saturate-100 lg:min-h-[190px]" :src="`${article.n_cover}`">
            </div>
          </div>
          <ul>
            <li class="pt-5">
              <div class="text-sm text-center">{{ article.prd_name.toUpperCase() }}</div>
              <div class="lg:text-sm lg:min-h-[64px] font-semibold py-3 min-h-[60px]">
                {{ article.n_title }}
              </div>
            </li>
            <div class="lg:pt-6 pb-10 text-center">
              <a :href="`${article.n_url}`" target="_blank"
                class="border border-gray-600 rounded-full px-10 py-[12px] text-sm tracking-wider hover:bg-black hover:text-white duration-300">了解更多</a>
            </div>
          </ul>
        </a>
      </div>
    </div>
  </div>
  <DetailFooter></DetailFooter>
</template>



<script setup>
import { ref, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import DetailFooter from '@/components/common/DetailFooter.vue';

const pageTitle = ref('卡尔约翰钢琴 德国SAUTER（首德）钢琴购买指南');
const pianos = ref([]);
const prd_features = ref("");
const cat1_banner = ref("");
const selectedTab = ref('tab1');
const prd_spec = ref("");
const uni_disc = ref("");
const videos = ref([]);
const articles = ref([]);
const listprice = ref("");
const route = useRoute();

//-----------popup img
const showModal = ref(false)
const currentImage = ref('')

const openModal = (imageSrc) => {
  currentImage.value = imageSrc
  showModal.value = true
}

const closeModal = () => {
  showModal.value = false
}

watch(showModal, (newVal) => {
  if (newVal) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }//Disable the scroll bar
})
//-----------popup img

const cleanHtml = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  doc.querySelectorAll('[style]').forEach(element => element.removeAttribute('style'));
  return doc.body.innerHTML;
};

const fetchData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) throw new Error(`Failed to fetch ${url}`);
  return response.json();
};

const fetchProducts = async () => {
  try {
    const id = route.params.id;

    const [pianoArrays, videoArrays, articleArrays] = await Promise.all([
      fetchData(`https://api.acton-shanghai.cn/openRDS/api/product/id/${id}`),
      fetchData(`https://api.acton-shanghai.cn/openRDS/api/video/prd/${id}`),
      fetchData(`https://api.acton-shanghai.cn/openRDS/api/news/prd/${id}`)
    ]);

    pianos.value = pianoArrays;
    videos.value = videoArrays;
    articles.value = articleArrays;

    const cat1_id = pianoArrays[0].cat1_id;
    const cat1Arrays = await fetchData(`https://api.acton-shanghai.cn/openRDS/api/cat1/list/15/id/${cat1_id}`);
    cat1_banner.value = cat1Arrays[0].cat1_banner;

    const updateHtml = (html) => cleanHtml(html).replace(/<img([^>]*)src="([^"]*)"/g, (match, p1, p2) => {
      return p2.includes('http') ? match : `<img${p1}src="https://www.actonchina.com${p2}"`;
    });

    prd_features.value = updateHtml(pianoArrays[0].prd_features);
    prd_spec.value = updateHtml(pianoArrays[0].prd_spec).replace(/<img([^>]*)src="([^"]*)"/g, '');
    uni_disc.value = updateHtml(pianoArrays[0].uni_disc).replace(/<img([^>]*)src="([^"]*)"/g, '');
    listprice.value = parseFloat(pianoArrays[0].prd_listprice).toFixed(2);
    listprice.value = Number(listprice.value);

  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

onMounted(() => {
  document.title = pageTitle.value;
  fetchProducts();
});

const handleTabClick = (tabName) => {
  selectedTab.value = tabName;
};
</script>


<style>
.content img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.content P {
  line-height: 28px;
  text-align: justify;
  margin-bottom: 10px;
  color: #353333;
}

.content strong {
  line-height: 35px;
}

.colored-logo {
  filter: invert(100%);
  /* 将SVG图片反相 */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

.fade-in {
  animation: fadeIn 0.2s ease-out;
}

.fade-out {
  animation: fadeOut 0.6s ease-in;
}
</style>
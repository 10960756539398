<template>
    <div class="bg-gray-200/50 content-center min-h-[150px] grid font-sans">
        <p class="text-center font-bold text-3xl">首德钢琴 资料下载</p>
    </div>
    <div class="container mx-auto py-5 max-w-[700px]">
        <div class="grid grid-cols-1">
            <div>
                <div class="leading-8 pb-5 contents" id="join">
                    <div class="text-justify mx-5" v-html="about"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
const pageTitle = ref('首德钢琴资料下载 德国SAUTER（首德）钢琴');

const about = ref('');

const cleanHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    doc.querySelectorAll('[style]').forEach(element => element.removeAttribute('style'));
    return doc.body.innerHTML;
};

const fetchProducts = async () => {
    try {
        const fetchData = async (url, num) => {
            const response = await fetch(url);
            const data = await response.json();
            return data.slice(0, num);
        };

        let Arrays = await fetchData('https://api.acton-shanghai.cn/openRDS/api/article/id/5', 1);
        const rawHtml = Arrays[0].n_content;
        about.value = cleanHtml(rawHtml).replace(/<img([^>]*)src="([^"]*)"/g, (match, p1, p2) => {
            return `<img${p1}src="${p2.includes('http') ? p2 : 'https://www.actonchina.com' + p2}"`;
        });
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

onMounted(() => {
    document.title = pageTitle.value;
    fetchProducts();
});
</script>

<style>
.contents p {
    margin-bottom: 20px;
}
.contents img {
  display: block;
  margin: 0 auto;
}
</style>
<template>
  <div class="container mx-auto pb-5 max-w-[1300px]">
    <div class="w-full">
      <img class="object-cover w-full" src="/images/head8.jpg">
    </div>
    <div class="py-6">
      <div class="mx-auto lg:max-w-[600px] pb-7">
        <img class="mx-auto pb-3" src="/images/sauter-designed-by-maly.jpg">
            <p class="text-justify leading-8 mx-5"><span class="font-bold">极简风，当下流行风向之一。</span>在我们的身边，不论是穿衣风格、家居设计，许多人都正在追求简约的呈现效果。在化繁为简的道路上，有一位大师已经将“简约”纯熟运用，他就是欧洲家居设计界教父般的人物 Peter Maly (彼得‧马利)。</p>
            <div class="py-10 text-center">
            <a href="https://www.bilibili.com/video/BV1K4421X7mL/" target="_blank"
                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">探索联名设计</a>
        </div>
      </div>
      <div class="flex justify-center pb-[10px] border-b border-gray-300 mb-14">
        <nav class="flex space-x-4">
          <div v-for="cat in cat2prd" :key="cat.id">
            <a :href="`/pianos/design/${cat.cat2_nameen.toLowerCase()}/${cat.id}`" :class="getClass(cat.id)">{{
            cat.cat2_namecn }}</a>
          </div>
        </nav>
      </div>
      <div class=" mx-auto  max-w-[900px]">
        <div class="grid grid-cols-1 gap-9 mx-5 lg:mx-0 lg:grid-cols-2 lg:gap-6">
          <div v-for="product in pianos" :key="product.id">
            <a class="relative" :href="`/design/detail/${product.id}`">
              <div class=" hover:-translate-y-1 duration-150 hover:opacity-85">
                <div class="flex justify-center rounded-md border border-yellow-600/20 p-6 hover:border-2 hover:border-yellow-600/60 duration-200">
                  <img class="object-cover saturate-100 lg:max-w-[22rem] lg:min-w-[22rem]" :src="`https://www.actonchina.com/${product.prd_cover}`">
                </div>
              </div>
              <div class="absolute z-10 -top-3 left-3">
                <div class="py-1 px-3 text-xs text-white bg-black shadow-md">{{
            product.b_nameen.toUpperCase() }}</div>
              </div>
              <ul>
                <li class="pt-5 text-center">
                  <div class="text-sm">{{ product.cat1_namecn }}</div>
                  <div class="text-xl font-semibold font-mono py-3">{{
            product.prd_name.toUpperCase()
                    }}</div>
                </li>
                <div class="pt-6 pb-10 text-center">
                  <a :href="`/design/detail/${product.id}`"
                    class="border border-gray-600 rounded-full px-10 py-[12px] text-sm tracking-wider hover:bg-black hover:text-white duration-300">了解更多</a>
                </div>
              </ul>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { ref, onMounted } from 'vue';
const pageTitle = ref('DESIGN设计系列型号列表 德国SAUTER（首德）钢琴购买指南');

export default {
  data() {
    return {
      pianos: [],
      cat2prd: [],
      cat1: [],
      cat2: [],
    };
  },
  created() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      try {
        const brand = this.$route.params.brand;
        const cat1 = this.$route.params.cat1;
        const cat2 = this.$route.params.cat2;
        this.cat1 = cat1;
        this.cat2 = cat2;

        //共用API接口
        let apiUrl = 'https://api.acton-shanghai.cn/openRDS/api/product/list';
        let cat2Url = 'https://api.acton-shanghai.cn/openRDS/api';


        if (cat1 === 'upright') {
          cat2Url += `/cat2/list/15/cat1/26`;
          apiUrl += `/15/cat1/26/cat2/62`;
        } else if (cat1 === 'grand') {
          cat2Url += `/cat2/list/15/cat1/26`;
          apiUrl += `/15/cat1/26/cat2/61`;
        } else if (cat1 === 'bench') {
          cat2Url += `/cat2/list/15/cat1/26`;
          apiUrl += `/15/cat1/26/cat2/63`;
        }

        const response1 = await fetch(apiUrl);
        const response2 = await fetch(cat2Url);
        //console.log(cat2Url);
        const pianoArrays = await response1.json();
        const cat2Arrays = await response2.json();
        this.pianos = pianoArrays;
        this.cat2prd = cat2Arrays;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },

    getClass(catId) {
      const cat2 = this.$route.params.cat2;
      console.log(catId, cat2)
      return {
        'py-2 px-4 hover:text-gray-600 text-gray-800 font-bold': true,
        'border-b-4 border-red-600': catId === cat2
      };
    }
  }
}
</script>
<template>
    <div class="bg-gray-200/50 content-center min-h-[150px] grid font-sans">
        <p class="text-center font-bold text-3xl">保修政策</p>
    </div>
    <div class="container mx-auto py-5 max-w-[900px]">
        <div class="grid grid-cols-1">
            <div>
                <div class="leading-8 pb-5 contents" id="join">
                    <div class="text-justify mx-5 mb-2" v-html="bWarranty"></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';

const pageTitle = ref('SAUTER（首德）钢琴保修条款');
const bWarranty = ref('');

const cleanHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    doc.querySelectorAll('[style]').forEach(element => element.removeAttribute('style'));
    return doc.body.innerHTML;
};

const fetchProducts = async () => {
    try {
        const fetchData = async (url, num) => {
            const response = await fetch(url);
            const data = await response.json();
            return data.slice(0, num);
        };

        let Arrays;
        Arrays = await fetchData('https://api.acton-shanghai.cn/openRDS/api/brand/id/16');
        // 清理 HTML 内容并存储在 cleanedHtml 中
        const rawHtml = Arrays[0].b_warrenty;
        bWarranty.value = cleanHtml(rawHtml);
        //---------------------------------------
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

onMounted(() => {
    document.title = pageTitle.value;
    fetchProducts();
});
</script>
<style>
.contents li {
    list-style-type: disc;
    margin-left: 18px;
}
</style>
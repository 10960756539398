<template>
  <div class="container mx-auto pb-5 max-w-[1300px]">
    <div class="w-full">
      <img class="object-cover w-full" src="/images/head1.jpg">
    </div>

    <!--div class="py-6">
      <div>
        <div class="grid grid-flow-col auto-cols-auto	gap-2 text-xs max-w-[250px] pb-5">
          <p class="flex">
            <svg class="pb-[5px]" height="20" width="20" fill="none" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1276_4030)">
                <path
                  d="M1.6665 10.1698C1.6665 8.26275 1.6665 7.30923 2.09917 6.51879C2.53184 5.72834 3.32229 5.23777 4.9032 4.25661L6.56986 3.22223C8.24099 2.18508 9.07656 1.6665 9.99984 1.6665C10.9231 1.6665 11.7587 2.18508 13.4298 3.22223L15.0965 4.25661C16.6774 5.23776 17.4678 5.72834 17.9005 6.51879C18.3332 7.30923 18.3332 8.26275 18.3332 10.1698V11.4373C18.3332 14.688 18.3332 16.3134 17.3569 17.3233C16.3805 18.3332 14.8092 18.3332 11.6665 18.3332H8.33317C5.19047 18.3332 3.61913 18.3332 2.64281 17.3233C1.6665 16.3134 1.6665 14.688 1.6665 11.4373V10.1698Z"
                  stroke="#5F5E5E" strokeWidth="1.5" />
                <path
                  d="M7.5 13.3335C8.20865 13.8588 9.07047 14.1668 10 14.1668C10.9295 14.1668 11.7914 13.8588 12.5 13.3335"
                  stroke="#5F5E5E" strokeLinecap="round" strokeWidth="1.5" />
              </g>
              <defs>
                <clipPath id="clip0_1276_4030">
                  <rect height="20" width="20" fill="white" />
                </clipPath>
              </defs>
            </svg> 首页
          </p>
          <p class="flex">
            <svg class="pb-[5px]" height="20" width="20" fill="none" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 4.16699L12.5 10.0003L7.5 15.8337" stroke="#5F5E5E" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="1.5" />
            </svg> SAUTER
          </p>
          <p class="flex">
            <svg class="pb-[5px]" height="20" width="20" fill="none" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 4.16699L12.5 10.0003L7.5 15.8337" stroke="#5F5E5E" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="1.5" />
            </svg> 列表
          </p>
        </div>
      </div>
    </div-->

    <div class="py-6">
      <div class="mx-auto lg:max-w-[600px] pb-7">
        <!--img class="mx-auto pb-3 max-w-[200px] object-none object-right-bottom" src="/images/sauter.jpg"-->
        <p class="text-justify leading-8 mx-5">正如 <span class="font-bold">SAUTER</span>钢琴多年来从未改变的一样——“完全德国制造”一直是SAUTER
          的标准。历经200年时光，沧桑几经变换，时代浮沉更迭，SAUTER 不仅见证了200多年来钢琴制造的发展史，更是将这个家族的文化进行升华——创新精神、坚持不懈、始终不变的初心。
        </p>
        <!--div class="py-10 text-center">
          <a href="https://mp.weixin.qq.com/s/vjOwdZS-JBhOH4IS3_-bzg" target="_blank"
                class="bg-red-600 rounded-3xl px-7 py-[12px] text-sm text-white tracking-wider hover:bg-red-500 duration-200">探索联名设计</a>
        </div-->
      </div>
      <div class="flex justify-center border-b border-gray-300 mb-10">
        <nav class="flex overflow-x-auto whitespace-nowrap space-x-4">
          <div class="min-h-[34px]" v-for="cat in cat2prd" :key="cat.id">
            <a :href="`/pianos/sauter/${cat1}/${cat.id}`" :class="getClass(cat.id)">{{ cat.cat2_namecn.toUpperCase()
              }}</a>
          </div>
        </nav>
      </div>
      <div class=" mx-auto  max-w-[900px]">
        <div class="pb-12">
          <p class="text-center text-2xl font-bold">{{ catname }}</p>
        </div>
        <div class="grid grid-cols-1 gap-9 mx-5 lg:mx-0 lg:grid-cols-2 lg:gap-6">
          <div v-for="product in pianos" :key="product.id">
            <a class="relative" :href="`/sauter/detail/${product.id}`">
              <div class="hover:-translate-y-1 duration-200 hover:opacity-85">
                <div
                  class="flex justify-center rounded-md border border-yellow-600/20 p-6 hover:border-2 hover:border-yellow-600/60 duration-200">
                  <img class="object-cover saturate-100 lg:max-w-[22rem] lg:min-w-[22rem]"
                    :src="`https://www.actonchina.com/${product.prd_cover}`">
                </div>
              </div>
              <div class="absolute z-10 -top-3 left-3">
                <div class="py-1 px-3 text-xs text-white bg-black shadow-md">{{
            product.b_nameen.toUpperCase() }}</div>
              </div>
              <ul>
                <li class="pt-5 text-center">
                  <div class="text-sm">{{ product.cat1_namecn }}</div>
                  <div class="text-xl font-semibold font-mono py-3 min-h-[60px]">{{
            product.prd_name.toUpperCase()
          }}</div>
                </li>
                <div class="lg:pt-6 pb-10 text-center">
                  <a :href="`/sauter/detail/${product.id}`"
                    class="border border-gray-600 rounded-full px-10 py-[12px] text-sm tracking-wider hover:bg-black hover:text-white duration-300">了解更多</a>
                </div>
              </ul>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
const pageTitle = ref('德国SAUTER（首德）钢琴购买指南');

export default {
  setup() {
    const pianos = ref([]);
    const cat2prd = ref([]);
    const cat1 = ref('');
    const route = useRoute();
    const catname = ref('');

    const fetchProducts = async () => {
      try {
        const brand = route.params.brand;
        const cat1Param = route.params.cat1;
        const cat2 = route.params.cat2;
        cat1.value = cat1Param;

        //共用API接口
        let apiUrl = 'https://api.acton-shanghai.cn/openRDS/api/product/list';
        let cat2Url = 'https://api.acton-shanghai.cn/openRDS/api';

        if (brand === 'sauter') {
          if (cat1Param === 'upright') {
            cat2Url += `/cat2/list/15/cat1/25`;
            apiUrl += `/15/cat1/25/cat2/${cat2}`;
            catname.value = '立式钢琴';
          } else if (cat1Param === 'grand') {
            cat2Url += `/cat2/list/15/cat1/24`;
            apiUrl += `/15/cat1/24/cat2/${cat2}`;
            catname.value = '';
          }
        }

        const response1 = await fetch(apiUrl);
        const response2 = await fetch(cat2Url);
        const pianoArrays = await response1.json();
        const cat2Arrays = await response2.json();
        pianos.value = pianoArrays;
        cat2prd.value = cat2Arrays;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    const getClass = (catId) => {
      const cat2 = route.params.cat2;
      console.log(catId, cat2);
      return {
        'py-2 px-4 hover:text-gray-600 text-gray-800 font-bold': true,
        'border-b-4 border-red-600': catId === cat2,
      };
    };

    onMounted(() => {
      fetchProducts();
    });

    return {
      pianos,
      cat2prd,
      cat1,
      fetchProducts,
      getClass,
      catname,
    };
  },
};

</script>